// Generated by Framer (36a78eb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {yXLwrvkBM: {hover: true}};

const cycleOrder = ["yXLwrvkBM"];

const serializationHash = "framer-D0zjL"

const variantClassNames = {yXLwrvkBM: "framer-v-1pcf2zm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, width, ...props}) => { return {...props, d_k4Egym9: link ?? props.d_k4Egym9} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, d_k4Egym9, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "yXLwrvkBM", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-D0zjL", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={d_k4Egym9} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx("framer-1pcf2zm", className)} framer-17k4irr`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"yXLwrvkBM"} ref={ref ?? ref1} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{"yXLwrvkBM-hover": {backgroundColor: "var(--token-a5df8b6b-54d5-4388-af79-6b45e29e509b, rgb(31, 30, 30))"}}} {...addPropertyOverrides({"yXLwrvkBM-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fit", intrinsicHeight: 443, intrinsicWidth: 2942, pixelHeight: 443, pixelWidth: 2942, sizes: "168px", src: "https://framerusercontent.com/images/C4ORIS6LM0eNDcAUyIU8q4ERI.png", srcSet: "https://framerusercontent.com/images/C4ORIS6LM0eNDcAUyIU8q4ERI.png?scale-down-to=512 512w, https://framerusercontent.com/images/C4ORIS6LM0eNDcAUyIU8q4ERI.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/C4ORIS6LM0eNDcAUyIU8q4ERI.png?scale-down-to=2048 2048w, https://framerusercontent.com/images/C4ORIS6LM0eNDcAUyIU8q4ERI.png 2942w"}} className={"framer-19fvo8n"} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"iBNACM8QL"}/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-D0zjL [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-D0zjL .framer-17k4irr { display: block; }", ".framer-D0zjL .framer-1pcf2zm { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 8px 16px 8px 16px; position: relative; text-decoration: none; width: min-content; }", ".framer-D0zjL .framer-19fvo8n { aspect-ratio: 4.958904109589041 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 34px); overflow: visible; position: relative; width: 168px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-D0zjL .framer-1pcf2zm { gap: 0px; } .framer-D0zjL .framer-1pcf2zm > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-D0zjL .framer-1pcf2zm > :first-child { margin-left: 0px; } .framer-D0zjL .framer-1pcf2zm > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"mqw09EZic":{"layout":["auto","auto"]}}}
 * @framerVariables {"d_k4Egym9":"link"}
 * @framerImmutableVariables true
 */
const FramerkTc6ra5fI: React.ComponentType<Props> = withCSS(Component, css, "framer-D0zjL") as typeof Component;
export default FramerkTc6ra5fI;

FramerkTc6ra5fI.displayName = "Logo Link";

FramerkTc6ra5fI.defaultProps = {height: 50, width: 200};

addPropertyControls(FramerkTc6ra5fI, {d_k4Egym9: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerkTc6ra5fI, [])